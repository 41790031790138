import './App.css';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/pages/Layout';
import Home from './components/pages/Home';
import PageNotFound from './components/pages/Not-found';
import PhotoBoothPage from './components/pages/Photo-Booth';
import SettingsPage from './components/pages/Settings';
import DownloadPage from './components/pages/Download';
import LoaderPrimary from './components/assets/Loader';
import Gallery from './components/pages/Gallery';
import { app } from './config/firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState } from "react";

function App() {

  const [user, setUser] = useState(null);
  const [loader, setLoader] = useState(true);

  const auth = getAuth(app);

  onAuthStateChanged(auth, (cred) => {
      if(cred){
          setUser(cred);
      }else{
          setUser(null);
      }
      
      setLoader(false);

  });

  if(loader) {
      return <div className='Loader_Cont'><LoaderPrimary /></div>
  }

  if(user){
    return (
      <div className="App">
        <Routes>
          <Route path="/" element={<Layout user={user}/>} >
            <Route index element={<Home user={user} />} />
            <Route path="photobooth" element={<PhotoBoothPage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="download/:event/:picId/:type" element={<DownloadPage /> } />
            <Route path="gallery" element={<Gallery /> } />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </div>
    );
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout user={user}/>} >
          <Route index element={<Home user={user} />} />
          <Route path="download/:event/:picId/:type" element={<DownloadPage /> } />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;