import { useEffect, useState, useRef } from "react";
import styles from "../../../styles/StartCamera.module.css";
import Counter from "../Counter";
import LoaderPrimary from "../../../assets/Loader";
import { useFrameStore } from "../../../function/Frame";
import { Orientation } from '../../../function/Orientation';
import { usePhotoStore } from '../../../function/Photos';
import Resolution from '../../../function/Resolution';
import StartCamera from '../../../function/Camera';
import PhotoPreview from "../Photo_preview";
import "context-filter-polyfill";

export default function CreatePhoto( props ) {

    const { frames } = useFrameStore();

    const { addPhotos } = usePhotoStore();

    const orientation = Orientation();

    const res = Resolution();

    const [takePhoto, setTakePhoto] = useState(false);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [countdownFinish, setCountdownFinish] = useState(false);
    const [rendering, setRendering] = useState(false);
    const [photoUrl, setPhotoUrl] = useState(null);

    const videoRef = useRef(null);
    const streamRef = useRef(null);
    const photoTakenRef = useRef(false);
    const frame = useRef();

    useEffect(() => {
      if(videoRef.current && videoPlaying) {
          document.getElementById("photo_video").style.filter = (props.videoFilter.filter);
      }
        
      }, [props.videoFilter, videoRef, videoPlaying])
    
      useEffect(() => {
        if(!videoPlaying) {
            frame.current = new Image();
            frame.current.crossOrigin = "anonymous";
            frame.current.src = frames[0];
            
            StartCamera(videoRef.current, streamRef.current, videoPlaying, setVideoPlaying,);
        }
    
      }, [videoPlaying, frames])

      useEffect(() => {
        if(countdownFinish && !photoUrl && !photoTakenRef.current) {

            function renderPhoto() {
              const video = videoRef.current;
              const canvas = document.createElement("canvas");
          
              // Add willReadFrequently: true for better performance
              const ctx = canvas.getContext('2d');
          
              canvas.width = res.width;
              canvas.height = res.height;
          
              ctx.imageSmoothingEnabled = true;
              ctx.imageSmoothingQuality = "high";
              ctx.filter = (props.videoFilter.filter);
              ctx.drawImage(video, 0, 0, res.width, res.height);
              // If image is already loaded, apply it immediately
              ctx.filter = 'none';
              ctx.drawImage(frame.current, 0, 0, res.width, res.height);
              const data = canvas.toDataURL("image/png", 1.0);
              if(data){
                setRendering(true);
                setTakePhoto(false);
                setCountdownFinish(false);
                photoTakenRef.current=true;
                setTimeout(() => {
                  setPhotoUrl(data);
                  addPhotos(data);
                  setRendering(false);
                }, 3000);
              };
            }

            renderPhoto();
            
        }
      }, [countdownFinish, photoUrl, res, props.videoFilter.filter, addPhotos])

      useEffect(() => {
        if(!photoUrl){
          setTakePhoto(false);
          setCountdownFinish(false);
          photoTakenRef.current=false;
        }
      }, [photoUrl])

      function handleTakePhoto(e) {
        e.preventDefault();
        setTakePhoto(true);
      }

    return (
        <>
          <div className={`${photoUrl? ("") : (styles.div_hide)} ${styles.take_photo_container} `} >
            <PhotoPreview orientation={orientation} setView={props.setView}  setPhotoUrl={setPhotoUrl} view={"photo"} />
          </div>

          <div className={`${styles.take_photo_container} ${photoUrl? (styles.div_hide) : ("")}`} >
              <div className={`${styles.camera} ${rendering? (styles.div_hide) : ("")}`} id="camera">
                      <video className={`${props.isFullScreen? (styles.fullscreen_video) : (styles.video)} `} id="photo_video" preload="none" ref={videoRef}></video>
                      <img className={`${orientation === "landscape" ? styles.frame_landscape : styles.frame_orientation}`} src={frames[0]} alt="frame"/>
                      <button className={`${styles.capture_btn} ${takePhoto ?(styles.display_hide) : ""}`} id="capture" type="button" onClick={e => {handleTakePhoto(e)}}>Take Photo</button>
              </div>

              <div className={`${!rendering? (styles.div_hide) : ("")}`}>
                <LoaderPrimary />
              </div>
      
              {takePhoto && (
              <Counter videoFilter={props.videoFilter} setView={props.setView} orientation={orientation} stream={videoRef.current} setCountdownFinish={setCountdownFinish} setTakePhoto={setTakePhoto} view={"photo"} />
              )}
          </div>
        </>
    )
      
}
