import { useEffect, useState, useRef} from "react";
import FullScreenIcon from "../../assets/Icons/fullscreen.png";
import FilterIcon from "../../assets/Icons/filter.png";
import styles from "../../styles/Photobooth.module.css";
import CreatePhoto from "./Create_photo";
import EnterEmail from "./Email";
import QrCode from "./QR_Code";
import CreateGif from "./Create_gif";
import ColorSettings from "../Settings/Color";

export default function PhotoBoothPage() {

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [view, setView] = useState("");
    const [ colorSettings, setColorSettings] = useState(false);
    const [filter, setFilter] = useState(getFilter());
    const [videoFilter, setVideoFilter] = useState({});

    const screenEl = useRef(null);

    useEffect(() => {
        setVideoFilter({filter:filter})
    }, [filter])

    useEffect(() => {

        screenEl.current.addEventListener("fullscreenchange", () => {
            if(!document.fullscreenElement){
                setIsFullScreen(false)
            }
        })
    }, [screenEl])

    function fullScreenMode(e) {
        e.preventDefault();
        const fullScreenDiv = document.getElementById("fullscreen");
        setIsFullScreen(true)
        if (fullScreenDiv.requestFullscreen) {
            fullScreenDiv.requestFullscreen();
          } else if (fullScreenDiv.mozRequestFullScreen) {
            fullScreenDiv.mozRequestFullScreen();
          } else if (fullScreenDiv.webkitRequestFullscreen) {
            fullScreenDiv.webkitRequestFullscreen();
          } else if (fullScreenDiv.msRequestFullscreen) {
            fullScreenDiv.msRequestFullscreen();
          }
    }

    function settingsButton(e) {
        e.preventDefault();

        if(colorSettings){
            setColorSettings(false);
        }else{
            setColorSettings(true);
        }
    }

    function selectBtn(e) {
        e.preventDefault();
        setView(e.target.value);
    }

    return (
        <div id="target" className={styles.container}>
            <button className={styles.fullscreen_btn} type="button" onClick={e => {fullScreenMode(e)}} ><img className={styles.fullscreen_btn_icon} src={FullScreenIcon} alt="Fullscreen icon"/></button>
            <button className={styles.settings_btn} type="button" onClick={e => {settingsButton(e)}}><img className={styles.settings_btn_icon} src={FilterIcon} alt="settings icon" /></button>
            <div className={`${styles.fullscreen_container} ${isFullScreen &&(styles.fullscreen_mode)}`} id="fullscreen" ref={screenEl} >
                {!view && (<div className={styles.button_container}>
                    <button className={styles.button} value="photo" type="button" onClick={e => {selectBtn(e)}}>Photo</button>
                    <button className={styles.button} value="gif" type="button" onClick={e => {selectBtn(e)}}>Gif</button>
                </div>)}
                {view === "photo" && (<CreatePhoto videoFilter={videoFilter} setView={setView} isFullScreen={isFullScreen} />)}
                {view === "email" && (<EnterEmail setView={setView} />)}
                {view === "qrcode" && (<QrCode setView={setView}  />)}
                {view === "gif" && (<CreateGif videoFilter={videoFilter} setView={setView} isFullScreen={isFullScreen}   />)}
            </div>
            {colorSettings && (<ColorSettings setFilter={setFilter} />)}
        </div>
    )
}

function getFilter() {
    const filter = sessionStorage.getItem("filter");

    if(filter) {
        return filter;
    }else{
        return ("none")
    }
}
